<template>
  <section class="lesson-attachments">
    <div
      v-for="(attachment, index) in lesson.attachments"
      :key="index"
      class="lesson-attachment"
      @click.prevent="download(attachment)"
    >
      <IconSax name="folder-2" class="lesson-attachment__icon" />
      <div class="lesson-attachment__info">
        <span class="lesson-attachment__title">{{ attachment.title }}</span>
        <span class="lesson-attachment__size">{{ bytesToSize(attachment.size) }}</span>
      </div>
      <div class="lesson-attachment__download">
        <IconSax name="import" />
      </div>
    </div>
  </section>
</template>
<script>

import axios from "axios";
import Cookies from "js-cookie";

export default {
  name: "LessonAttachements",
  props: ["lesson"],
  data() {
    return {
      link: null,
    }
  },
  methods: {
    async download(attachment) {
      const { course_id, module_id, id } = this.lesson
      const response = await axios({
        url:
          process.env.VUE_APP_API_HOST +
          `/course/${course_id}/module/${module_id}/lesson/${id}/attachment/${attachment.id}/download`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: Cookies.get("auth_greennCourse"),
        },
      });
      const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: attachment.mime }));
      const cdnURL = attachment.cdn_url;
      const extension = cdnURL.substring(cdnURL.lastIndexOf('.'))

      this.link.href = fileURL;
      this.link.setAttribute("download", attachment.title + extension);
      this.link.click();
      window.URL.revokeObjectURL(fileURL);
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (!bytes) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
  },
  mounted() {
    this.link = document.createElement("a");
    document.body.appendChild(this.link);
    this.link.style = "display: none";
  }
}

</script>

<style lang="scss" scoped>

.lesson-attachments {
  @include flex-row;
  gap: 1rem;
  padding: 1rem;
  flex-wrap: wrap;
  align-self: stretch;
}

.lesson-attachment {
  cursor: pointer;
  @include flex-row;
  min-width: 25rem;
  flex: 1;
  gap: 0.75rem;
  padding: 1rem;
  color: var(--fontcolor);
  border-radius: 0.5rem;
  background: var(--accordion-header);
}

.lesson-attachment:hover{
  background: var(--accordion-header-active-hover);
      color: var(--maincolor);
}

.lesson-attachment::v-deep .svgCss svg path{
  fill: var(--maincolor);
}

@media screen and (max-width: 768px) {
  .lesson-attachment {
    min-width: 100%;
  }
  .lesson-attachments {
    padding: 0;
  }
}

::v-deep .lesson-attachment__icon {

  & svg path {
    fill: var(--maincolor) !important;
  }
}

.lesson-attachment__info {
  @include flex-column;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lesson-attachment__title {
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.lesson-attachment__size {
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

::v-deep .lesson-attachment__download {
  filter: brightness(115%);
  padding: 0.5rem;
  background-color: var(--contentcolor);
  border-radius: 0.5rem;
  height: 40px;

  & svg path {
    fill: var(--maincolor) !important;
  }
}

</style>
